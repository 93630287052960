/*
 * SubscriptionsTable.tsx (AbstractECommerce)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file SubscriptionsTable.tsx
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import {
  capitalizeFirstCharacterOnString,
  formatDate,
  isStringEmptyOrNullOrUndefined
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import { useTranslation } from 'react-i18next';
import {
  IPageEvent,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import { currencyFormatter } from '@abstract/abstractwebcommon-shared/utils/currencyFormatter';
import {
  IInvoiceDetails,
  ISubscription
} from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Subscription';
import { SubscriptionStatus } from '@abstract/abstractwebcommon-shared/enum/ecommerce/braintreeSubscriptionStatus';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import Button from 'react-bootstrap/Button';
import { SubscriptionSharedFucntions } from '../../../Utils/Subscriptions/sharedFunctions';
import { useDispatch, useSelector } from 'react-redux';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import SubscriptionsForm from './SubscriptionForm';
import ConfirmationDialog from '@abstract/abstractwebcommon-client/ConfirmationDialog/ConfirmationDialog';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { isRole } from '../../../Utils/Formatter';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';
import { MultiSelect } from 'primereact/multiselect';
import { IProduct } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/Product';
import { fetchAllProductsGroupByType } from '../../../Store/Products';
import RetrySubscriptionForm from './RetrySubscriptionForm';
import { IPublicEnvironmentVariables } from '@abstract/abstractwebcommon-shared/interfaces/ecommerce/EnvironmentVariables';
import { getApplicationEnvironmentVariablesAction } from 'src/Store/ShopSettings';
import { EnvironmentType } from '@abstract/abstractwebcommon-shared/enum/environmentType';

/**
 * Interface for SubscriptionsTable properties.
 */
interface ISubscriptionsTableProperties {
  subscriptions: ISubscription[];
  isLoading: boolean;
  tablePayload: ITablePayload /**  Table payload. */;
  handlePageUpdate: (
    event: IPageEvent
  ) => void /**< Handle Table page update. */;
  handleSortUpdate: (
    event: ISortEvent
  ) => void /**< Handle sort update event. */;
  handleFilterUpdate: (
    event: string
  ) => void /**< Handle filter update event. */;
  refundSubscription: (
    subscriptionID: string
  ) => void /**< Handle refund subscription functionality. */;
  cancelSubscription: (
    subscriptionID: string
  ) => void /**< Handle cancel subscription functionality. */;
  retrySubscription: (
    subscriptionID: string,
    transactionPrice?: string
  ) => void /**< Handle retry subscription functionality. The Production environment won't pass the transaction price.*/;
  handleSubscriptionPaymentMethodUpdate: (
    subscriptionID: string,
    paymentMethodNonce: string
  ) => void /**< Handle subscription payment method update functionality. */;
  resetPaymentMethod: () => void /**< To reset payment method. */;
  getSubscriptionPaymentMethod: (
    subscriptionID: string
  ) => void /**< Get subscription payment method. */;
  totalRecords: number;
  onTerminateSubscription: (
    subscriptionID: string
  ) => Promise<void> /**< Handle terminate subscription */;
  handleProductFilterUpdate: (
    filter: Record<string, any>
  ) => void /**< Handle product filter update event */;
}

const SubscriptionsTable = (properties: ISubscriptionsTableProperties) => {
  const translation = useTranslation().t;
  const dispatch = useDispatch();
  const store = useSelector((state) => state.shopSettings);

  const sharedSubscriptionStore = useSelector(
    (state) => state.sharedSubscriptions
  );

  const environmentVariables: IPublicEnvironmentVariables = useSelector(
    (state) => state.shopSettings.environmentVariables
  ); /**< Get environmentVariables to enable/disabled the retry subscription form based on the environment type. */

  const refundWindow: number = store.list.refundWindow;
  const sort: ISortEvent = properties.tablePayload?.sort; /**< Sort */
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const [
    isRefundConfirmationPopupVisible,
    setRefundConfirmationPopupVisible
  ] = useState<boolean>(false);

  const [
    isRetrySubscriptionFormVisible,
    setRetrySubscriptionFormVisible
  ] = useState<boolean>(
    false
  ); /**< Used to hide or display the confirmation popup for the retry button. */

  const [
    isSubscriptionsWithPastDueStatus,
    setSubscriptionsWithPastDueStatus
  ] = useState<boolean | null>(
    null
  ); /**< Used to hide or display the retry button column */

  const [isProductionEnvironment, setProductionEnvironment] = useState<boolean>(
    true
  ); /**< Indicates if the component is being rendered in the Production environment. We need to display or hide the price field from the Subscription retry form depeding on the environment type. */

  const [
    isCancelConfirmationPopupVisible,
    setCancelConfirmationPopupVisible
  ] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [subscriptionID, setSubscriptionID] = useState<string | null>(
    null
  ); /**< Get subscription to perform actions, like refund and cancel subscription. */
  const [isDialogVisible, setDialogVisible] = useState<boolean>(
    false
  ); /**< Is dialog visible */
  const [
    editSubscription,
    setEditSubscription
  ] = useState<ISubscription | null>(); /**< Edit subscription */
  const [
    isShowingTerminateConfirmationModal,
    setShowTerminateConfirmationModal
  ] = useState<boolean>(false); /**< Show Confirmation Modal. */
  const role: string[] = JSON.parse(LocalStorage.getRole()); /**< User role. */
  const adminRoleUUID: string =
    store?.list?.adminRoleUUID; /**< Admin role UUID. */
  const isAdminRole: boolean = isRole([adminRoleUUID], role);
  const [selectedProducts, setSelectedProducts] = useState<
    Record<string, any>[]
  >([]); /**< Selected Products. */
  const productsState = useSelector((state) => state.products);

  // Show terminate popup
  const terminateButtonClicked = (): void => {
    setShowTerminateConfirmationModal(true);
  };

  // Terminate subscription on Accept
  const onAcceptTerminateSubscription = async (): Promise<void> => {
    await asyncErrorHandler(
      properties.onTerminateSubscription(subscriptionID as string)
    );
    setShowTerminateConfirmationModal(false);
    setSubscriptionID(null);
  };

  /// Hide confirmation on reject
  const onRejectTerminateSubscription = (): void => {
    setShowTerminateConfirmationModal(false);
  };

  const getTerminateConfirmationModal = () => {
    return (
      <ConfirmationDialog
        dialogTitle={translation(
          'adminSubscriptions.terminate_dialog_header.warning'
        )}
        dialogText={translation(
          'adminSubscriptions.terminate_dialog_header.warning_text'
        )}
        isShowConfirmationModal={isShowingTerminateConfirmationModal}
        onReject={onRejectTerminateSubscription}
        onAccept={onAcceptTerminateSubscription}
      />
    );
  };

  // Triggers on product multiselect change - Sets the value to the filter
  const onProductChange = (event: any): void => {
    const selectedProductsGroupByType: Record<string, any>[] =
      event.value; /**< Selected Products group by type. */
    setSelectedProducts(selectedProductsGroupByType);

    const products: IProduct[] = selectedProductsGroupByType.flatMap(
      (selectedObject: Record<string, any>) => selectedObject.products
    ); /**< Selected products */

    const filteredValue: string[] = products.map(
      (product: IProduct) => product.name
    ); /**< filteredValue */

    let updatedFilter: Record<string, any> = {};

    if (filteredValue && filteredValue.length) {
      // If values exist then update the filter object with product key
      updatedFilter = {
        ...properties.tablePayload.filter,
        'transaction.products.productName': { value: filteredValue }
      };
    } else {
      // If not omit product key from object
      updatedFilter = { ...properties.tablePayload.filter };
      if (updatedFilter['transaction.products.productName']) {
        delete updatedFilter['transaction.products.productName'];
      }
    }

    const updatedPayload: ITablePayload = properties.tablePayload;
    updatedPayload.filter = updatedFilter;
    properties.handleProductFilterUpdate(updatedPayload);
  };

  /// Div containing header action buttons
  const header = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer"></div>
      {isAdminRole ? (
        <div className="headerTableContainer header-search-filter">
          <SearchBar
            onSearchTermChanged={(data) => properties.handleFilterUpdate(data)}
          />
          <MultiSelect
            name="product"
            inputId="productFilter"
            value={selectedProducts}
            options={productsState.allProductsGroupByType?.map(
              (productsGroupByType: Record<string, IProduct[]>) => {
                return {
                  value: productsGroupByType,
                  label: translation(
                    `fields.productType.${productsGroupByType._id}`
                  )
                };
              }
            )}
            onChange={onProductChange}
            placeholder={translation('fields.product.type')}
            className="p-column-filter"
            filter
            maxSelectedLabels={1}
          />
        </div>
      ) : (
        <SearchBar
          onSearchTermChanged={(data) => properties.handleFilterUpdate(data)}
        />
      )}
    </div>
  );

  useEffect(() => {
    if (isAdminRole) {
      dispatch(fetchAllProductsGroupByType()); /**< To fetch all products. */
    }
  }, []);

  useEffect(() => {
    if (environmentVariables) {
      setProductionEnvironment(
        environmentVariables.ENVIRONMENT_TYPE === EnvironmentType.Production
      );
    }
  }, [environmentVariables]);

  const GetRowExpansionTemplate = (values: { rowData: ISubscription }) => (
    <>
      <tr>
        <th>{translation('adminSubscriptions.table.customer_name')}</th>
        <td>{values.rowData['customerFullName']}</td>
      </tr>
      <tr>
        <th>
          {translation('adminSubscriptions.table.expansion_row.invoiceNo')}
        </th>
        <td>
          {values.rowData?.allTransactionsInvoiceDetails?.length
            ? values.rowData.allTransactionsInvoiceDetails.map(
                (item: IInvoiceDetails, index: number) => (
                  <>
                    {index ? ', ' : ''}
                    <a
                      key={index}
                      href={item.invoiceLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-transparent"
                    >
                      {item.invoiceNo}
                    </a>
                  </>
                )
              )
            : ''}
        </td>
      </tr>
      <tr>
        <th>{translation('adminSubscriptions.table.plan_name')}</th>
        <td>{values.rowData['planName']}</td>
      </tr>
      <tr>
        <th>{translation('adminSubscriptions.table.expansion_row.plan_id')}</th>
        <td>{values.rowData['planID']}</td>
      </tr>
      <tr>
        <th>{translation('adminSubscriptions.table.product')}</th>
        <td>{values.rowData?.product?.name}</td>
      </tr>
      {!isStringEmptyOrNullOrUndefined(
        values.rowData.braintreeSubscriptionID!
      ) ? (
        <tr>
          <th>
            {translation(
              'adminSubscriptions.table.expansion_row.subscription_braintree_id'
            )}
          </th>
          <td>{values.rowData['braintreeSubscriptionID']}</td>
        </tr>
      ) : (
        <></>
      )}
      <tr>
        <th>{translation('adminSubscriptions.table.price')}</th>
        <td>{currencyFormatter(Number(values.rowData['price']))}</td>
      </tr>
      {!isStringEmptyOrNullOrUndefined(values.rowData.nextBillingDate!) ? (
        <tr>
          <th>
            {translation(
              'adminSubscriptions.table.expansion_row.nextBillingDate'
            )}
          </th>
          <td>
            {values.rowData.nextBillingDate
              ? formatDate(values.rowData.nextBillingDate as string, {
                  isTime: false,
                  isTwentyFour: false,
                  isSecondsHide: false,
                  isDateWithoutTimestamp: true
                })
              : '-'}
          </td>
        </tr>
      ) : (
        <></>
      )}
      <tr>
        <th>
          {translation(
            'adminSubscriptions.table.expansion_row.number_billing_cycles'
          )}
        </th>
        <td>{values.rowData['numberOfBillingCycles'] ?? 'Never expires'}</td>
      </tr>
      <tr>
        <th>
          {translation(
            'adminSubscriptions.table.expansion_row.current_billing_cycle'
          )}
        </th>
        <td>{values.rowData['currentBillingCycle']}</td>
      </tr>
      <tr>
        <th>{translation('adminSubscriptions.table.billing_start')}</th>
        <td>
          {values.rowData.billingPeriodStartDate
            ? formatDate(values.rowData.billingPeriodStartDate, {
                isTime: false,
                isTwentyFour: false,
                isSecondsHide: false,
                isDateWithoutTimestamp: true
              })
            : '-'}
        </td>
      </tr>
      <tr>
        <th>{translation('adminSubscriptions.table.billing_end')}</th>
        <td>
          {values.rowData.billingPeriodEndDate
            ? formatDate(values.rowData.billingPeriodEndDate, {
                isTime: false,
                isTwentyFour: false,
                isSecondsHide: false,
                isDateWithoutTimestamp: true
              })
            : '-'}
        </td>
      </tr>
      <tr>
        <th>
          {translation(
            'adminSubscriptions.table.expansion_row.subscriptionStart'
          )}
        </th>
        <td>{formatDate(values.rowData.subscriptionStartDate!)}</td>
      </tr>
      <tr>
        <th>
          {translation(
            'adminSubscriptions.table.expansion_row.subscriptionEnd'
          )}
        </th>
        <td>
          {values.rowData.subscriptionEndDate
            ? formatDate(values.rowData.subscriptionEndDate)
            : 'Never expires'}
        </td>
      </tr>
      {values.rowData.lastBillingDate != null ? (
        <tr>
          <th>
            {translation(
              'adminSubscriptions.table.expansion_row.lastBillingDate'
            )}
          </th>
          <td>{formatDate(values.rowData.lastBillingDate)}</td>
        </tr>
      ) : (
        <></>
      )}
      <tr>
        <th>{translation('adminSubscriptions.table.status')}</th>
        <td>{capitalizeFirstCharacterOnString(values.rowData['status'])}</td>
      </tr>
      <tr>
        <th>{translation('adminSubscriptions.table.created_at')}</th>
        <td>
          {values.rowData.createdAt ? formatDate(values.rowData.createdAt) : ''}
        </td>
      </tr>
      <tr>
        <th>{translation('adminSubscriptions.table.last_updated')}</th>
        <td>
          {values.rowData.updatedAt ? formatDate(values.rowData.updatedAt) : ''}
        </td>
      </tr>
      <tr>
        <th>
          {translation('adminSubscriptions.table.expansion_row.terminated')}
        </th>
        <td>
          {
            <ShowCheckOrUncheckIcon
              value={values.rowData.isTerminated as boolean}
            />
          }
        </td>
      </tr>
      {/* /* Right now Pedro things we don't need the past due reason on the subscription page, but we may need in the future, so, I'm commeting out this code for now. AW-3338 */}
      {/* {isStringEmptyOrNullOrUndefined(values.rowData.pastDueReason!) ? (
        <tr>
          <th>
            {translation(
              'adminSubscriptions.table.expansion_row.pastDueReason'
            )}
          </th>
          <td>{values.rowData['pastDueReason']}</td>
        </tr>
      ) : (
        <></>
      )} */}
    </>
  );

  const renderExpansionRows = (rowData: ISubscription) => (
    <>
      <ExpansionRow>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>
    </>
  );

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data._id]: true });
    }
  };

  // Used only in the Staging and Local environments.
  const onAcceptRetrySubscriptionFormConfirmation = (values: any): void => {
    // We should allow changing the value on the Staging end Local environment.
    const amountToRefund: string | undefined = isProductionEnvironment
      ? undefined
      : values.transactionPrice;

    properties.retrySubscription(subscriptionID as string, amountToRefund);
  };

  // Accept dialog function for Refund option.
  const onAcceptRefundConfirmation = (): void => {
    setRefundConfirmationPopupVisible(false);
    properties.refundSubscription(subscriptionID as string);
  };

  // Reject dialog function for Refund option.
  const onRejectRefundConfirmation = (): void => {
    setRefundConfirmationPopupVisible(false);
  };

  // Accept dialog function for Cancel option.
  const onAcceptCancelConfirmation = (): void => {
    setCancelConfirmationPopupVisible(false);
    properties.cancelSubscription(subscriptionID as string);
  };

  // Reject dialog function for Cancel option.
  const onRejectCancelConfirmation = (): void => {
    setCancelConfirmationPopupVisible(false);
  };

  /// Show edit dialog
  const onEditButtonClicked = async (rowData: ISubscription): Promise<void> => {
    await asyncErrorHandler(
      properties.getSubscriptionPaymentMethod(rowData._id as string)
    );
    setEditSubscription(rowData);
    setDialogVisible(true);
  };

  useEffect(() => {
    if (properties.subscriptions && properties?.subscriptions.length > 0) {
      setSubscriptionsWithPastDueStatus(
        properties.subscriptions.some(
          (subscription: ISubscription) =>
            subscription.status === SubscriptionStatus.PastDue
        )
      );
    }
  }, [properties.subscriptions]);

  useEffect(() => {
    dispatch(
      getApplicationEnvironmentVariablesAction()
    ); /**< To fetch environment variables to enable/disabled the retry subscription form based on the environment type. */
  }, []);

  const getDataTable = () => {
    return (
      <BaseDatatable
        value={properties.subscriptions}
        isLoading={!properties.subscriptions}
        expandedRows={expandedRows}
        dataKey="_id"
        onRowExpand={expandRow}
        onRowCollapse={() => setExpandedRows({})}
        rowExpansionTemplate={renderExpansionRows}
        sortField={sort && sort.sortField}
        sortOrder={sort && sort.sortOrder}
        first={properties.tablePayload.skip}
        rows={properties.tablePayload.limit}
        onPage={properties.handlePageUpdate}
        onSort={properties.handleSortUpdate}
        header={header}
        totalRecords={properties.totalRecords}
      >
        <Column
          expander
          className="p-0 col-width-45"
          headerClassName="p-0 col-width-45"
        />
        <Column selectionMode="multiple" className="d-none" />
        <Column
          field="customerFullName"
          header={translation('adminSubscriptions.table.customer_name')}
          sortable
          body={(rowData: ISubscription) => (
            <DatatableColumn
              title={translation('adminSubscriptions.table.customer_name')}
              data={
                <>
                  <span>{rowData.customerFullName ?? ''}</span>
                  <br></br>
                  <span>{rowData.customer.email ?? ''}</span>
                </>
              }
            />
          )}
          className="col-width-350"
          headerClassName="col-width-350"
        />
        <Column
          field="transaction.products.productName"
          header={translation('adminSubscriptions.table.product')}
          headerClassName={`d-table-cell d-sm-none ${
            isAdminRole ? 'd-xxl-table-cell' : 'd-xl-table-cell'
          }`}
          className={`d-table-cell d-sm-none ${
            isAdminRole ? 'd-xxl-table-cell' : 'd-xl-table-cell'
          }`}
          sortable
          body={(rowData: ISubscription) => (
            <DatatableColumn
              title={translation('adminSubscriptions.table.product')}
              isEllipsisVisible={false}
              data={rowData?.product?.name}
            />
          )}
        />
        <Column
          field="priceAsNumber" // Note: Sorting in priceAsNumber(type is double) field
          header={translation('adminSubscriptions.table.price')}
          headerClassName={`d-table-cell d-sm-none p-col-price ${
            isAdminRole ? 'd-xl-table-cell' : 'd-md-table-cell'
          }`}
          className={`d-table-cell d-sm-none p-col-price ${
            isAdminRole ? 'd-xl-table-cell' : 'd-md-table-cell'
          }`}
          sortable
          body={(rowData: ISubscription) => (
            <DatatableColumn
              title={translation('adminSubscriptions.table.price')}
              data={currencyFormatter(Number(rowData.price))}
            />
          )}
        />
        <Column
          field="subscriptionStartDate"
          header={translation('adminSubscriptions.table.subscriptionStart')}
          headerClassName="createdDateCol d-table-cell d-sm-none d-3xl-table-cell"
          className="createdDateCol d-table-cell d-sm-none d-3xl-table-cell"
          sortable
          body={(rowData: ISubscription) => (
            <DatatableColumn
              title={translation('adminSubscriptions.table.subscriptionStart')}
              data={formatDate(rowData.subscriptionStartDate!)}
            />
          )}
        />
        <Column
          field="subscriptionEndDate"
          header={translation('adminSubscriptions.table.subscriptionEnd')}
          // We should hide this column soon in case the Retry button is displayed in the table.
          headerClassName={`createdDateCol d-table-cell d-sm-none ${
            isSubscriptionsWithPastDueStatus
              ? 'd-first-custom-admin-subscription-page-breakpoint-table-cell'
              : 'd-xxl-table-cell'
          }`}
          className={`createdDateCol d-table-cell d-sm-none ${
            isSubscriptionsWithPastDueStatus
              ? 'd-first-custom-admin-subscription-page-breakpoint-table-cell'
              : 'd-xxl-table-cell'
          }`}
          sortable
          body={(rowData: ISubscription) => (
            <DatatableColumn
              title={translation('adminSubscriptions.table.subscriptionEnd')}
              data={
                rowData.subscriptionEndDate
                  ? formatDate(rowData.subscriptionEndDate)
                  : 'Never expires'
              }
            />
          )}
        />
        <Column
          field="status"
          header={translation('adminSubscriptions.table.status')}
          // We should hide this column soon in case the Retry button is displayed in the table.
          headerClassName={`custom-header-min-width-allowed d-table-cell d-sm-none ${
            isSubscriptionsWithPastDueStatus
              ? 'd-second-custom-admin-subscription-page-breakpoint-table-cell'
              : 'd-second-custom-admin-subscription-page-breakpoint-table-cell'
          }`}
          className={`custom-header-min-width-allowed d-table-cell d-sm-none ${
            isSubscriptionsWithPastDueStatus
              ? 'd-second-custom-admin-subscription-page-breakpoint-table-cell'
              : 'd-second-custom-admin-subscription-page-breakpoint-table-cell'
          }`}
          sortable
          body={(rowData: ISubscription) => (
            <DatatableColumn
              title={translation('adminSubscriptions.table.status')}
              data={rowData.status}
            />
          )}
        />
        <Column
          field="createdAt"
          header={translation('adminSubscriptions.table.created_at')}
          className="createdDateCol d-table-cell d-sm-none d-3xl-table-cell"
          headerClassName="createdDateCol d-table-cell d-sm-none d-3xl-table-cell"
          sortable
          body={(rowData: ISubscription) => (
            <DatatableColumn
              title={translation('adminSubscriptions.table.created_at')}
              data={rowData.createdAt ? formatDate(rowData.createdAt) : ''}
            />
          )}
        />
        <Column
          field="refundSubscription"
          className="absolute-position-responsive-screen col-width-45 p-2 d-table-cell d-sm-none d-md-table-cell"
          body={(rowData: ISubscription) => {
            return (
              <div className="d-flex justify-content-center version-button-container">
                <Button
                  className={`${
                    SubscriptionSharedFucntions.isRefundOptionAvailable(
                      rowData,
                      refundWindow
                    ) &&
                    !sharedSubscriptionStore.isRequestingEndpoint &&
                    rowData.price != '0.00'
                      ? ''
                      : 'custom-disabled-button'
                  } custom-action-column-action-position d-none d-sm-block`}
                  style={{ minWidth: '80px' }}
                  onClick={(event) => {
                    if (
                      SubscriptionSharedFucntions.isRefundOptionAvailable(
                        rowData,
                        refundWindow
                      ) &&
                      !sharedSubscriptionStore.isRequestingEndpoint &&
                      rowData.price != '0.00'
                    ) {
                      setRefundConfirmationPopupVisible(true);
                      setConfirmPopupTarget(event.target);
                      setSubscriptionID(rowData._id as string);
                    }
                  }}
                  disabled={
                    !SubscriptionSharedFucntions.isRefundOptionAvailable(
                      rowData,
                      refundWindow
                    ) ||
                    sharedSubscriptionStore.isRequestingEndpoint ||
                    rowData.price == '0.00'
                  }
                >
                  {translation('sharedSubscription.table.refund_button')}
                </Button>

                <Button
                  className={`custom-action-column-action-position d-block d-sm-none ${
                    isAdminRole
                      ? 'position-absolute-mr-90'
                      : 'position-absolute-mr-60'
                  }`}
                  variant="outline"
                  onClick={(event) => {
                    if (
                      SubscriptionSharedFucntions.isRefundOptionAvailable(
                        rowData,
                        refundWindow
                      ) &&
                      !sharedSubscriptionStore.isRequestingEndpoint &&
                      rowData.price != '0.00'
                    ) {
                      setRefundConfirmationPopupVisible(true);
                      setConfirmPopupTarget(event.target);
                      setSubscriptionID(rowData._id as string);
                    }
                  }}
                  disabled={
                    !SubscriptionSharedFucntions.isRefundOptionAvailable(
                      rowData,
                      refundWindow
                    ) ||
                    sharedSubscriptionStore.isRequestingEndpoint ||
                    rowData.price == '0.00'
                  }
                >
                  <i
                    className={`bi bi-piggy-bank editIcon fa-lg ${
                      SubscriptionSharedFucntions.isRefundOptionAvailable(
                        rowData,
                        refundWindow
                      ) && rowData.price != '0.00'
                        ? ''
                        : 'custom-disabled-icon'
                    }`}
                  />
                </Button>
              </div>
            );
          }}
        />

        <Column
          field="cancelSubscription"
          className="absolute-position-responsive-screen col-width-45 p-2"
          body={(rowData: ISubscription) => {
            return (
              <div className="d-flex justify-content-center version-button-container">
                <Button
                  className={`${
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Canceled ||
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Refunded ||
                    sharedSubscriptionStore.isRequestingEndpoint
                      ? 'custom-disabled-button'
                      : ''
                  } custom-action-column-action-position d-none d-sm-block`}
                  style={{ minWidth: '80px' }}
                  onClick={(event) => {
                    if (
                      (rowData.localSubscriptionStatus !==
                        SubscriptionStatus.Canceled ||
                        rowData.localSubscriptionStatus !==
                          SubscriptionStatus.Refunded) &&
                      !sharedSubscriptionStore.isRequestingEndpoint
                    ) {
                      setCancelConfirmationPopupVisible(true);
                      setConfirmPopupTarget(event.target);
                      setSubscriptionID(rowData._id as string);
                    }
                  }}
                  disabled={
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Canceled ||
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Refunded ||
                    sharedSubscriptionStore.isRequestingEndpoint
                  }
                >
                  {translation('sharedSubscription.table.cancel_button')}
                </Button>

                <Button
                  className={`custom-action-column-action-position d-block d-sm-none ${
                    isAdminRole
                      ? 'position-absolute-mr-60'
                      : 'position-absolute-mr-30'
                  }`}
                  variant="outline"
                  onClick={(event) => {
                    if (
                      (rowData.localSubscriptionStatus !==
                        SubscriptionStatus.Canceled ||
                        rowData.localSubscriptionStatus !==
                          SubscriptionStatus.Refunded) &&
                      !sharedSubscriptionStore.isRequestingEndpoint
                    ) {
                      setCancelConfirmationPopupVisible(true);
                      setConfirmPopupTarget(event.target);
                      setSubscriptionID(rowData._id as string);
                    }
                  }}
                  disabled={
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Canceled ||
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Refunded ||
                    sharedSubscriptionStore.isRequestingEndpoint
                  }
                >
                  <i
                    className={`bi bi-x-square editIcon fa-lg ${
                      rowData.localSubscriptionStatus !==
                        SubscriptionStatus.Canceled ||
                      rowData.localSubscriptionStatus !==
                        SubscriptionStatus.Refunded
                        ? ''
                        : 'custom-disabled-icon'
                    }`}
                  />
                </Button>
              </div>
            );
          }}
        />

        <Column
          field="retrySubscription"
          className={`absolute-position-responsive-screen col-width-45 p-2 ${
            isSubscriptionsWithPastDueStatus
              ? 'd-table-cell d-sm-none d-md-table-cell'
              : 'd-none'
          }`}
          body={(rowData: ISubscription) => {
            return (
              <div className="d-flex justify-content-center version-button-container">
                <Button
                  className={`${
                    rowData.status === SubscriptionStatus.PastDue
                      ? ''
                      : 'custom-disabled-button'
                  } custom-action-column-action-position d-none d-sm-block ${
                    sharedSubscriptionStore.isRetryingSubscription ||
                    sharedSubscriptionStore.isRequestingEndpoint
                      ? 'custom-disabled-button'
                      : ''
                  }`}
                  style={{ minWidth: '80px' }}
                  onClick={(event) => {
                    if (
                      (rowData.status === SubscriptionStatus.PastDue &&
                        !sharedSubscriptionStore.isRetryingSubscription) ||
                      sharedSubscriptionStore.isRequestingEndpoint
                    ) {
                      setRetrySubscriptionFormVisible(true);
                      setConfirmPopupTarget(event.target);
                      setEditSubscription(rowData);
                      setSubscriptionID(rowData._id as string);
                    }
                  }}
                  disabled={
                    rowData.status !== SubscriptionStatus.PastDue ||
                    sharedSubscriptionStore.isRetryingSubscription ||
                    sharedSubscriptionStore.isRequestingEndpoint
                  }
                >
                  {translation('adminSubscriptions.table.retryButton')}
                </Button>

                <Button
                  className="custom-action-column-action-position d-block d-sm-none position-absolute-mr-120"
                  variant="outline"
                  onClick={(event) => {
                    if (
                      (rowData.status === SubscriptionStatus.PastDue &&
                        !sharedSubscriptionStore.isRetryingSubscription) ||
                      sharedSubscriptionStore.isRequestingEndpoint
                    ) {
                      setRetrySubscriptionFormVisible(true);
                      setConfirmPopupTarget(event.target);
                      setEditSubscription(rowData);
                      setSubscriptionID(rowData._id as string);
                    }
                  }}
                  disabled={
                    rowData.status !== SubscriptionStatus.PastDue ||
                    sharedSubscriptionStore.isRetryingSubscription ||
                    sharedSubscriptionStore.isRequestingEndpoint
                  }
                >
                  <i
                    className={`bi bi-arrow-clockwise editIcon fa-lg ${
                      rowData.status === SubscriptionStatus.PastDue ||
                      !sharedSubscriptionStore.isRetryingSubscription ||
                      sharedSubscriptionStore.isRequestingEndpoint
                        ? ''
                        : 'custom-disabled-icon'
                    }`}
                  />
                </Button>
              </div>
            );
          }}
        />

        <Column
          field="terminateSubscription"
          className={`${
            isAdminRole
              ? 'absolute-position-responsive-screen col-width-45 p-2'
              : 'd-none'
          }`} // Terminate request is only allowed for admin role
          body={(rowData: ISubscription) => {
            return (
              <div className="d-flex justify-content-center version-button-container">
                <Button
                  variant="danger"
                  onClick={(event) => {
                    if (
                      (rowData.status !== SubscriptionStatus.Canceled ||
                        rowData.localSubscriptionStatus !==
                          SubscriptionStatus.Refunded) &&
                      !sharedSubscriptionStore.isRequestingEndpoint
                    ) {
                      setSubscriptionID(rowData._id);
                      terminateButtonClicked();
                    }
                  }}
                  className={`${
                    rowData.status === SubscriptionStatus.Canceled ||
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Refunded ||
                    sharedSubscriptionStore.isRequestingEndpoint
                      ? 'custom-disabled-button'
                      : ''
                  } custom-action-column-action-position d-none d-sm-block`}
                  disabled={
                    rowData.status === SubscriptionStatus.Canceled ||
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Refunded ||
                    sharedSubscriptionStore.isRequestingEndpoint
                  }
                >
                  {translation('adminSubscriptions.table.header.terminate')}
                </Button>
                <Button
                  variant="outline"
                  onClick={(event) => {
                    if (
                      (rowData.status !== SubscriptionStatus.Canceled ||
                        rowData.localSubscriptionStatus !==
                          SubscriptionStatus.Refunded) &&
                      !sharedSubscriptionStore.isRequestingEndpoint
                    ) {
                      setSubscriptionID(rowData._id as string);
                      terminateButtonClicked();
                    }
                  }}
                  className={`custom-action-column-action-position d-block d-sm-none position-absolute-mr-30`}
                  disabled={
                    rowData.status === SubscriptionStatus.Canceled ||
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Refunded ||
                    sharedSubscriptionStore.isRequestingEndpoint
                  }
                >
                  <i
                    className={`bi bi-x-square editIcon fa-lg text-danger ${
                      rowData.status !== SubscriptionStatus.Canceled ||
                      rowData.localSubscriptionStatus !==
                        SubscriptionStatus.Refunded
                        ? ''
                        : 'custom-disabled-icon'
                    }`}
                  />
                </Button>
              </div>
            );
          }}
        />

        <Column
          field="edit"
          className={`custom-action-column-action-position col-width-45 p-0`}
          body={(rowData: ISubscription) => {
            return (
              <Button
                className={`${
                  rowData.localSubscriptionStatus ===
                    SubscriptionStatus.Canceled ||
                  rowData.localSubscriptionStatus ===
                    SubscriptionStatus.Refunded ||
                  sharedSubscriptionStore.isRequestingEndpoint ||
                  rowData.price == '0.00'
                    ? 'custom-disabled-icon'
                    : ''
                } custom-action-column-action-position`}
                onClick={() => {
                  onEditButtonClicked(rowData);
                }}
                variant="outline"
                disabled={
                  rowData.localSubscriptionStatus ===
                    SubscriptionStatus.Canceled ||
                  rowData.localSubscriptionStatus ===
                    SubscriptionStatus.Refunded ||
                  sharedSubscriptionStore.isRequestingEndpoint ||
                  rowData.price == '0.00'
                }
              >
                <i
                  className={`bi bi-pencil-square editIcon fa-lg ${
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Canceled ||
                    rowData.localSubscriptionStatus ===
                      SubscriptionStatus.Refunded ||
                    rowData.price == '0.00' ||
                    sharedSubscriptionStore.isRequestingEndpoint
                      ? 'custom-disabled-icon'
                      : ''
                  }`}
                />
              </Button>
            );
          }}
        />
      </BaseDatatable>
    );
  };

  /// Triggered on SubscriptionsForm dialog hide event.
  const onHide = () => {
    setEditSubscription(null);
    setDialogVisible(false);
    properties.resetPaymentMethod();
  };

  /// Returns the subscriptions dialog form.
  const getSubscriptionsDialog = () => {
    return (
      <DialogWrapper
        isDialogVisible={isDialogVisible}
        onHide={() => onHide()}
        headerTitle={translation(
          'sharedSubscription.subscription_dialog_header'
        )}
      >
        <SubscriptionsForm
          editSubscription={editSubscription}
          onHide={onHide}
          handleUpdate={properties.handleSubscriptionPaymentMethodUpdate}
        />
      </DialogWrapper>
    );
  };

  // useEffect to handle the retry subscription form properly.
  useEffect(() => {
    if (sharedSubscriptionStore.isRetrySubscriptionSuccessful) {
      setRetrySubscriptionFormVisible(false);
    }
  }, [sharedSubscriptionStore.isRetrySubscriptionSuccessful]);

  return (
    <>
      {getDataTable()}
      {getSubscriptionsDialog()}
      {getTerminateConfirmationModal()}

      <RetrySubscriptionForm
        isLoading={sharedSubscriptionStore.isRetryingSubscription}
        isRetrySubscriptionFormVisible={isRetrySubscriptionFormVisible}
        onHide={() => setRetrySubscriptionFormVisible(false)}
        handleSubmit={onAcceptRetrySubscriptionFormConfirmation}
        subscription={editSubscription as ISubscription}
        isProductionEnvironment={isProductionEnvironment}
      />

      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={isRefundConfirmationPopupVisible}
        title={translation('/confirm_messages.refund_subscription')}
        onAccept={onAcceptRefundConfirmation}
        onReject={onRejectRefundConfirmation}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translation('/confirm_messages.no')}
        acceptLabel={translation('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />

      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={isCancelConfirmationPopupVisible}
        title={translation('/confirm_messages.cancel_subscription')}
        onAccept={onAcceptCancelConfirmation}
        onReject={onRejectCancelConfirmation}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translation('/confirm_messages.no')}
        acceptLabel={translation('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
    </>
  );
};

export default SubscriptionsTable;
